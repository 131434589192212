import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/empty-layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const ThankYouPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  return (
    <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
        <Layout>
            <Seo
            title="Thank You for Signing Up"
            />
            <section id="thank-you-page">
                <div className="container thank-you-container">
                    <div className="thank-you-row row">
                        <div className="col-lg-12">
                            <div className="thank-you-div text-center">
                                <h2 className="text-light font-weight-bold text-uppercase text-center">
                                    Thank You For Your Help
                                </h2>
                                <p className="text-center text-white">
                                    Meetings withing the Peer Club are forming constantly and now we know a little about you and your interests we can keep you informed of agendas and peer gatherings that match your needs.
                                </p>
                                <p className="text-center text-white">
                                    We'll be in touch with you to help you get the most out of The Peer Club.
                                </p>
                                <a href="https://peer-club-backend.web.app/profile?step=1">
                                    <button className="thank-you-btn">
                                        OK
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    </>
  )
}

export default ThankYouPage
